<template>
  <div>
    <Feedback />
  </div>
</template>

<script>
import Feedback from '@components/Feedback'

export default {
  page: {
    title: 'Feedback'
  },

  components: {
    Feedback
  }
}
</script>
