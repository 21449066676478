<template>
  <div>
    <div class="bg-primary">
      <div class="w-full max-w-screen-xl px-6 py-6 mx-auto sm:py-16">
        <div class="relative items-center -mx-6 xl:flex">
          <div
            class="max-w-2xl px-6 mx-auto md:text-center"
          >
            <h1
              class="text-3xl leading-none text-primary sm:text-4xl md:text-5xl"
            >
              Feedback
            </h1>
            <p
              class="mt-4 text-lg leading-normal text-primary-offset"
            >
              How can we improve our platform to better meet your needs?
            </p>
          </div>
        </div>
      </div>

      <div class="relative z-10 text-gray-100">
        <svg
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
          class="fill-current"
          style="margin-top: -2.5rem; width: 100%; height: 11rem; display: block;"
        >
          <path
            d="M0,224L40,218.7C80,213,160,203,240,213.3C320,224,400,256,480,240C560,224,640,160,720,144C800,128,880,160,960,149.3C1040,139,1120,85,1200,96C1280,107,1360,181,1400,218.7L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div class="bg-gray-100">
        <div class="w-full max-w-screen-xl p-6 mx-auto">
          <div class="md:flex md:justify-between md:items-center">
            <div class="relative z-20 px-8 py-6 mb-16 -mt-40 text-gray-900 bg-white border-2 border-gray-200 rounded shadow-lg md:w-1/2 md:mb-4 lg:mr-16">
              <form
                novalidate
                @submit.prevent
              >
                <label>
                  <span>Feedback</span>
                  <textarea
                    v-model="$v.formResponses.body.$model"
                    class="block w-full mt-1 bg-gray-100 resize-none form-textarea focus:outline-none focus:border-gray-800 focus:shadow-none"
                    rows="10"
                    placeholder="How can we improve our platform?"
                  ></textarea>
                </label>
                <ErrorsInline v-if="errors">
                  <span v-if="!$v.formResponses.body.required">This field is required.</span>
                </ErrorsInline>

                <div class="flex items-center justify-center pt-8">
                  <BaseButton
                    v-if="!feedbackSubmitted"
                    class="w-full"
                    :loading="processing"
                    type="submit"
                    @click="submit"
                  >
                    Submit
                  </BaseButton>
                  <transition
                    appear
                    enter-active-class="transition-all duration-500 ease-out"
                    leave-active-class="transition-all duration-150 ease-in"
                    enter-class="opacity-0"
                    leave-class="opacity-100"
                    enter-to-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <p v-if="feedbackSubmitted">
                      Thank you for submitting your feedback
                    </p>
                  </transition>
                </div>
              </form>
            </div>
            <div class="md:w-1/2">
              <figure class="block px-4">
                <span class="block w-16 h-2 mb-4 bg-secondary"></span>
                <blockquote
                  class="relative mx-auto mb-4 text-2xl"
                >
                  <template v-if="$usesFeature('candidate-wizard')">
                    Focusing on data reduced the subjective decision making from our hiring process and allowed us to reduce bias and make better hiring decisions.
                  </template>
                  <template v-if="$usesFeature('classic-jobs')">
                    An unbeatable service, impressive platform and fantastic value for money.
                  </template>
                </blockquote>
                <figcaption class="mt-8">
                  <div class="flex items-center justify-end">
                    <div class="inline-flex flex-shrink-0 border-2 rounded-full border-secondary">
                      <img
                        v-if="$usesFeature('candidate-wizard')"
                        class="w-16 h-16 rounded-full"
                        src="https://res.cloudinary.com/picked/image/upload/v1607596624/cms/picked-testimonials-1607596624.jpg"
                        alt="Izzie, Head of HR, iZettle, Sweden"
                      >
                      <img
                        v-if="$usesFeature('classic-jobs')"
                        class="w-16 h-16 rounded-full"
                        src="https://res.cloudinary.com/picked/image/upload/cms/tc-testimonials-1614597327.jpg"
                        alt="Laurence Fishman, Partner, NLPCA"
                      >
                    </div>
                    <div class="ml-4">
                      <div class="text-base font-medium leading-6 text-gray-600">
                        Izzie, Head of HR, iZettle, Sweden
                      </div>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>

          <section class="mx-auto text-center">
            <h2 class="pt-8 mb-8 font-bold tracking-wider text-gray-600 uppercase">
              More than 3000 companies trust {{ satelliteName }}
            </h2>
            <ul class="flex flex-wrap items-center justify-center max-w-6xl mx-auto -my-8 text-center lg:flex-row lg:justify-between">
              <li class="flex flex-col justify-center w-full h-32 p-4 sm:w-32">
                <img src="~@assets/images/logos/google.svg" />
              </li>
              <li class="flex flex-col justify-center w-full h-32 p-4 sm:w-32">
                <img src="~@assets/images/logos/pwc.svg" />
              </li>
              <li class="flex flex-col justify-center w-full h-32 p-4 sm:w-32">
                <img src="~@assets/images/logos/hotels.svg" />
              </li>
              <li class="flex flex-col justify-center w-full h-32 p-4 sm:w-32">
                <img src="~@assets/images/logos/bose.svg" />
              </li>
              <li class="flex flex-col justify-center w-full h-32 p-4 sm:w-32">
                <img src="~@assets/images/logos/uber.svg" />
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import feedbackApi from '@api/feedback'

export default {
  components: {
    ErrorsInline
  },

  mixins: [validationMixin],

  data() {
    return {
      processing: false,
      errors: false,
      feedbackSubmitted: false,
      satelliteName: process.env.VUE_APP_SATELLITE_NAME,

      formResponses: {
        body: ''
      }
    }
  },

  validations: {
    formResponses: {
      body: {
        required
      }
    }
  },

  methods: {
    submit() {
      this.$v.formResponses.$touch()

      this.errors = this.$v.formResponses.body.$anyError

      if (this.errors) {
        this.processing = false
        return
      }

      if (this.errors === false) {
        this.processing = true

        return feedbackApi.createClientFeedback({ body: this.formResponses.body })
          .then((response) => {
            console.log(response)
            this.processing = false
            this.feedbackSubmitted = true
          })
          .catch(error => {
            this.processing = false
            throw error
          })
      }
    }
  }
}
</script>
